import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { create } from 'zustand'
import { Handler, OptionalType } from '@digital-magic/ts-common-utils'
import { verificationCodeStorageKey } from '@constants/configuration'
import { EmailVerificationSessionId } from '@api/endpoints/public'

type State = Readonly<{
  verificationSessionId: OptionalType<EmailVerificationSessionId>
}>
type Actions = Readonly<{
  setVerificationSessionId: Handler<EmailVerificationSessionId>
  resetVerificationSessionId: Handler<void>
}>

const emptyState = {
  verificationSessionId: undefined
}

export const useVerificationSessionStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...emptyState,
        setVerificationSessionId: (verificationSessionId) => set(() => ({ verificationSessionId })),
        resetVerificationSessionId: () => set(() => emptyState)
      }),
      {
        name: verificationCodeStorageKey,
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
