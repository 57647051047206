import * as React from 'react'
import { downloadFile } from '@utils/file-utils'
import { FileId, fileUrl } from '@api/endpoints/files'
import { Link, LinkProps } from '@controls/Link'

type DownloadLinkByFileIdProps = Omit<LinkProps, 'onClick' | 'href' | 'to'> & {
  fileId: FileId
}

export const DownloadLinkByFileId: React.FC<DownloadLinkByFileIdProps> = ({ fileId, ...props }) => {
  const onDownloadClick = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault()
    await downloadFile(fileUrl(fileId))
  }

  return <Link href="#" onClick={onDownloadClick} {...props} />
}
