import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestErrorHandler } from '@api/types'
import { EmailVerificationSessionId, useConfirmEmailVerification } from '@api/endpoints/public'
import { CircleLoader } from '@controls/CircleLoader'
import { Text } from '@controls/Text'
import { VerificationInput } from '@controls/VerificationInput'
import { VerificationCodeInputStyled } from './VerificationCodeInput.styles'

type VerificationCodeInputProps = {
  sessionId: EmailVerificationSessionId
  onVerificationSuccess: () => void
  onVerificationError: RequestErrorHandler
}

export const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({
  sessionId,
  onVerificationSuccess,
  onVerificationError
}): React.ReactElement => {
  const { t } = useTranslation()
  const [verificationCode, setVerificationCode] = React.useState<string>('')

  const confirmEmailVerification = useConfirmEmailVerification({
    onError: onVerificationError,
    onSuccess: onVerificationSuccess
  })

  const onChange = (value: string): void => {
    setVerificationCode(value)
  }

  React.useEffect(() => {
    if (verificationCode.length === 6) {
      confirmEmailVerification.mutate({ sessionId, verificationCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode])

  return (
    <VerificationCodeInputStyled>
      <CircleLoader loading={confirmEmailVerification.isLoading}>
        <Text color="brown" weight="regular" textAlign="center" mb={4} px={2}>
          {t('pages.booking.form.verification.description')}
        </Text>
        <VerificationInput length={6} value={verificationCode} onChange={onChange} allowedRegex="^\d+$" />
      </CircleLoader>
    </VerificationCodeInputStyled>
  )
}
