/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useApiMutation } from '@digital-magic/react-common/lib/api/hooks'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts } from '@api/types'
import { sendAndReceive, sendOnly } from '@api/utils'
import { IdObject } from '../types'
import { ConfirmEmailVerificationRequest, EmailVerificationSessionId, StartEmailVerificationRequest } from './types'

const emailVerificationUrl = `${apiBaseUrl}/email-validation`
const emailVerificationSessionUrl = (sessionId: EmailVerificationSessionId): string =>
  `${emailVerificationUrl}/${sessionId}`
export const useStartEmailVerification = (opts?: ApiMutationOpts<IdObject, StartEmailVerificationRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: emailVerificationUrl,
        requestSchema: StartEmailVerificationRequest,
        responseSchema: IdObject,
        data
      }),
    ...opts
  })

type ConfirmEmailVerificationRequestWithSessionId = ConfirmEmailVerificationRequest & {
  sessionId: EmailVerificationSessionId
}

export const useConfirmEmailVerification = (
  opts?: ApiMutationOpts<void, ConfirmEmailVerificationRequestWithSessionId>
) =>
  useApiMutation({
    mutationFn: ({ sessionId, ...data }) =>
      sendOnly({
        method: 'put',
        url: emailVerificationSessionUrl(sessionId),
        requestSchema: ConfirmEmailVerificationRequest,
        data
      }),
    ...opts
  })
