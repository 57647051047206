import styled, { css } from 'styled-components'
import { BgType } from '@pages/Customer/types'

type Props = {
  bgType: BgType
}

export const SectionContainerStyled = styled.div<Props>(
  ({ theme, bgType }) => css`
    background-color: ${bgType === 'light' ? theme.colors.white : theme.colors.grey200};
    margin-left: auto;
    margin-right: auto;
    padding: ${theme.spacing(3, 8)};

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(3, 3)};
    }
  `
)
