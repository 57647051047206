import styled, { css } from 'styled-components'

export const VerificationInputStyled = styled('div')(
  ({ theme }) => css`
    position: relative;
    display: flex;
    column-gap: ${theme.spacing(1)};

    input {
      ${theme.mixins.engulfAbsolute}
      color: transparent;
      background: transparent;
      border: none;
      opacity: 0;
    }

    .verification-character {
      ${theme.mixins.square(theme.spacing(8))}
      ${theme.mixins.flexCenter}

      transition: all 0.2s;
      font-size: ${theme.global.typography.size['size-24']};
      border-bottom: 2px solid ${theme.colors.brown};
    }
  `
)
