import { ContactAvatar } from '@controls/ContactAvatar'
import { BookingContactStyled } from './BookingContact.styles'

export const BookingContact = (): React.ReactElement => {
  return (
    <BookingContactStyled bgType="dark">
      <ContactAvatar />
    </BookingContactStyled>
  )
}
