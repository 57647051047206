/* eslint-disable functional/immutable-data */
import { BlobType } from '@digital-magic/react-common/lib/utils/zod'
import { receiveOnly } from '@api/utils'

export const downloadFile = async (url: Readonly<string>): Promise<void> => {
  // TODO: WTF? Why Blob type is not inferred here automatically?
  const response = await receiveOnly({
    method: 'get',
    url: url,
    responseSchema: BlobType,
    responseType: 'blob'
  })
  const internalUrl = URL.createObjectURL(response as Blob)
  const a = document.createElement('a')
  a.href = internalUrl
  a.download = internalUrl.substring(internalUrl.lastIndexOf('/') + 1)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
