import styled, { css } from 'styled-components'

// TODO: Use native type instead
export type Alignment = 'right' | 'left' | 'center' | 'justify' | 'justify-all'

type Props = {
  $alignValue?: Alignment
  $alignLabel?: Alignment
}

export const DetailListStyled = styled('div')<Props>(
  ({ theme, $alignLabel, $alignValue }) => css`
    .detail-item {
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.grey500};
      }

      .detail-item-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${theme.spacing(1.5, 0)};
        font-size: ${theme.global.typography.size['size-16']};

        .detail-label {
          text-align: ${$alignLabel};
        }

        .detail-value {
          text-align: ${$alignValue};
        }

        ${theme.breakpoints.down('lg')} {
          font-size: ${theme.global.typography.size['size-14']};
        }
      }
    }
  `
)
