import * as React from 'react'
import classNames from 'classnames'
import { Alignment, DetailListStyled } from './DetailList.styles'

export type DetailListItem = {
  label: string | React.ReactNode
  className?: string
  value?: React.ReactNode
  children?: React.ReactNode
}

type DetailListProps = {
  className?: string
  alignValue?: Alignment
  alignLabel?: Alignment
  items: ReadonlyArray<DetailListItem>
}

export const DetailList = ({ className, items, alignLabel, alignValue }: DetailListProps): React.ReactElement => {
  return (
    <DetailListStyled className={className} $alignLabel={alignLabel} $alignValue={alignValue}>
      {items.map((item, i) => (
        <div key={`item-label-${i}`} className="detail-item">
          <div className={classNames('detail-item-main', item.className)}>
            <span className="detail-label">{item.label}</span>
            <span className="detail-value">{item.value ?? '-'}</span>
          </div>
          {item.children && <div>{item.children}</div>}
        </div>
      ))}
    </DetailListStyled>
  )
}
