import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isHttpError } from '@digital-magic/react-common/lib/api'
import { hasValue } from '@digital-magic/ts-common-utils'
import { RequestErrorHandler } from '@api/types'
import { EmailVerificationSessionId } from '@api/endpoints/public'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { useSnackbar } from 'notistack'
import { CircleLoader } from '@controls/CircleLoader'
import { VerificationCodeInput } from './VerificationCodeInput'

export type Props = {
  verificationSessionId: EmailVerificationSessionId
  loading: boolean
  onSuccess: React.DispatchWithoutAction
  onError: RequestErrorHandler
}

export const EmailVerification: React.FC<Props> = ({ verificationSessionId, loading, onSuccess, onError }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onVerificationError: RequestErrorHandler = (e) => {
    onError(e)

    if (isHttpError(e)) {
      if (e.httpStatus === 401) {
        return snackbar.enqueueSnackbar(t('pages.booking.form.verification.wrong_code'))
      }
      if (e.httpStatus === 403) {
        return snackbar.enqueueSnackbar(t('pages.booking.form.verification.session_ended'))
      }
    }

    return defaultErrorHandler(e)
  }

  return (
    <CircleLoader loading={loading} minHeight={150} display="flex" alignItems="center">
      {hasValue(verificationSessionId) && (
        <VerificationCodeInput
          sessionId={verificationSessionId}
          onVerificationSuccess={onSuccess}
          onVerificationError={onVerificationError}
        />
      )}
    </CircleLoader>
  )
}
