import { SvgIconProps } from '@mui/material'

export const CircleCheckIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 30.328 30.328" {...props}>
      <g transform="translate(-3973.5 -522.5)">
        <circle
          cx="14.164"
          cy="14.164"
          r="14.164"
          transform="translate(3974.5 523.5)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M3981.9,536.373l4.667,4.667,8.283-8.283"
          transform="translate(0.604 0.743)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
