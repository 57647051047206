import React from 'react'
import { VerificationInputStyled } from './VerificationInput.styles'

type VerificationInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'type'> & {
  length: number
  value: string
  onChange: (value: string) => void
  allowedRegex?: string
}

export const VerificationInput = ({
  length,
  value,
  allowedRegex,
  ...props
}: VerificationInputProps): React.ReactElement => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.substring(0, length)

    if (value.length === 0 || !allowedRegex || (allowedRegex && new RegExp(allowedRegex).test(value))) {
      props.onChange(value)
    }
  }

  return (
    <VerificationInputStyled>
      <input {...props} onChange={onChange} value={value} />
      {Array.from({ length }).map((_, i) => (
        <div key={i} className="verification-character">
          {value.charAt(i)}
        </div>
      ))}
    </VerificationInputStyled>
  )
}
