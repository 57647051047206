import { getTranslatedField } from '@utils/language-utils'
import { FileId, TranslatedFileId } from '@api/endpoints/files'
import { useGetLanguage } from '@hooks/useGetLanguage'

type TranslatedFileIdFn = (value: TranslatedFileId) => FileId

export const useTranslatedFileId = (): TranslatedFileIdFn => {
  const language = useGetLanguage()

  return (value): string => value[getTranslatedField(language)]
}
