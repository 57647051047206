import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '@pages/Customer/Customer.styles'

export const BookingContactStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    background: ${theme.colors.grey200};
    padding: ${theme.spacing(10, 0)};
    ${theme.mixins.flexCenterH}
  `
)
